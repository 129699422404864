export default {
	toStringPtBr: function (data) {
		data = new Date(data);
		var dia = String(data.getDate());
		var mes = String(data.getMonth() + 1);
		var ano = data.getFullYear();
		dia = dia.length === 2 ? dia : "0" + dia;
		mes = mes.length === 2 ? mes : "0" + mes;

		return dia + '/' + mes + '/' + ano;
	},

	converterData: function (valor) {
		if ((valor instanceof Date)) {
			return valor;

		} else if (typeof valor === 'string') {
			if (valor.indexOf("/") !== -1) {
				let dia = valor.split("/")[0];
				let mes = Number(valor.split("/")[1] - 1);
				let ano = valor.split("/")[2];

				return new Date(ano, mes, dia);

			} else if (valor.indexOf("-") !== -1) {
				let dia = valor.split("-")[2].substring(0, 2);
				let mes = Number(valor.split("-")[1] - 1);
				let ano = valor.split("-")[0];

				return new Date(ano, mes, dia);
			}
		}
		return null;
	},

	removerHora(data) {

		var dia = data.getDate();
		dia = String(dia).length == 1 ? '0' + String(dia) : dia;

		var mes = data.getMonth() + 1;
		mes = String(mes).length == 1 ? '0' + String(mes) : mes;

		var ano = data.getFullYear();

		return new Date(ano, mes, dia);
	},

	formatarDataValida: function (data, format) {
		var dia, mes, ano;
		if (!(data instanceof Date)) {
			dia = data.split('/')[0];
			mes = data.split('/')[1];
			ano = data.split('/')[2];
		} else {
			dia = data.getDate();
			dia = String(dia).length == 1 ? '0' + String(dia) : dia;

			mes = data.getMonth() + 1;
			mes = String(mes).length == 1 ? '0' + String(mes) : mes;

			ano = data.getFullYear();
		}

		format = format.replace('DD', dia).replace('MM', mes).replace('YYYY', ano);

		return format;
	},

	addDays(date, days) {
		let result = new Date(date);
		result.setDate(result.getDate() + days);

		return result;
	},

	obterMaiorData(data1, data2) {
		const primeiraData = new Date(data1.getFullYear(), data1.getMonth(), data1.getDate());
		const segundaData = new Date(data2.getFullYear(), data2.getMonth(), data2.getDate());

		if (primeiraData > segundaData) return 1;
		else if (segundaData > primeiraData) return 2;
		else return 0;
	},

	obterHorarioAtual() {
		const horaAtual = new Date().getHours();
		const minutoAtual = new Date().getMinutes();
		const horarioAtual = (horaAtual >= 10 ? horaAtual : '0' + horaAtual) + ':' + (minutoAtual >= 10 ? minutoAtual : '0' + minutoAtual);
		return horarioAtual;
	},

	toStringPtBrWithHour(data) {
		data = new Date(data);
		if (!(data instanceof Date && !isNaN(data)))
			return '';

		let dia = String(data.getDate());
		let mes = String(data.getMonth() + 1);
		let ano = data.getFullYear();

		let hora = String(data.getHours());
		let minuto = String(data.getMinutes());
		let segundo = String(data.getSeconds());

		dia = dia.length === 2 ? dia : '0' + dia;
		mes = mes.length === 2 ? mes : '0' + mes;
		hora = hora.length === 2 ? hora : '0' + hora;
		minuto = minuto.length === 2 ? minuto : '0' + minuto;
		segundo = segundo.length === 2 ? segundo : '0' + segundo;

		return dia + '/' + mes + '/' + ano + ' ' + hora + ':' + minuto + (segundo > 0 ? ':' + segundo : '');
	},

	obterDiasEntreDatas(dtIni, dtFim) {
		const diff = Math.abs(new Date(dtIni).getTime() - new Date(dtFim).getTime());
		return Math.ceil(diff / (1000 * 60 * 60 * 24));
	}
};
