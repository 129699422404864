<template>
  <div id="principal" class="container-fluid h-100 d-block m-0 p-0">
    <div v-if="telaPronta" class="d-flex flex-column bg-white m-0">
      <!-- Slot com informações dos filtros aplicados -->
      <div id="idDetalhesFiltros" class="w-100 d-flex flex-column shadow-sm mb-1 px-2 pt-1 fz-70 text-muted">
        <p class="m-0 p-0">
          <b>Período: </b><span>{{ filtro.dataInicial }}</span> <b> - </b><span>{{ filtro.dataFinal }}</span>
        </p>

        <p class="m-0 p-0">
          <b>Empresa: </b><span>{{ filtro.empresa }}</span>
        </p>
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex justify-content-between align-items-center mt-1">
            <div class="d-flex justify-content-between mt-1">
              <switches class="mx-2" color="yellow" v-model="exibirFinalizados"></switches>
              <span class="size-font-label text-muted">Exibir Finalizados</span>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center size-font-label text-muted pt-1" v-if="listaDeBalancos.length">
            {{ listaDeBalancos.length }}
            Registro{{ listaDeBalancos.length > 1 ? 's' : '' }}
          </div>
        </div>
      </div>

      <div id="idGridBalancos" class="d-flex flex-column mx-1 px-1 p-0" v-if="isMobile && listaDeBalancos.length">
        <div class="shadow-sm shadow-hover my-1" v-for="(balanco, index) in listaDeBalancos" v-bind:key="index">
          <div class="border" :class="{ 'border border-primary bg-light': balanco.IdBalanco == balancoSelecionado.IdBalanco }" @click="selecionaBalanco(balanco)">
            <div class="d-flex label-bold bg-default m-0 p-1">
              <b class="text-primary mr-1">{{ balanco.IdBalanco }}</b>
              <b class="text-muted"> - {{ balanco.Descricao }}</b>
            </div>
            <div class="d-flex">
              <div class="mr-auto px-2 mb-1 w-100">
                <div class="d-flex justify-content-between w-100">
                  <div class="text-right d-flex flex-column mr-1">
                    <p class="align-items-start text-left m-0">
                      <small class="label-orange">Data: </small>
                      <span class="text-muted size-font-label">
                        {{ balanco.DataLancamentoFormat }}
                      </span>
                    </p>
                    <p class="align-items-start text-left m-0">
                      <small class="label-orange">Quantidade de Seções: </small>
                      <span class="text-muted size-font-label">
                        {{ balanco.QtdeSecao }}
                      </span>
                    </p>
                  </div>
                  <div class="d-flex flex-column justify-content-end text-right">
                    <span
                      class="badge badge-pill"
                      :class="{
                        'badge-default': balanco.IdStatusBalanco == 1,
                        'badge-info': balanco.IdStatusBalanco == 2,
                        'badge-success': balanco.IdStatusBalanco == 3,
                        'badge-danger': balanco.IdStatusBalanco == 7,
                      }"
                    >
                      {{ obterDescricaoStatusBalanco(balanco.IdStatusBalanco) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Botão Voltar -->
      <div class="fixed-bottom bg-white mb-0">
        <div class="btn-group w-100 flex justify-content-end">
          <button :class="['btn btn-default ', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="$router.push({ name: 'filtros-balancos' })">
            <i class="fas fa-arrow-left fa-lg pl-2"></i>
            Voltar
          </button>
          <button :disabled="!habilitarExecucaoBalanco" :class="['btn btn-secondary', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="executarBalanco()">
            Executar
            <i class="fas fa-play fa-lg pl-2"></i>
          </button>
        </div>
      </div>

      <!-- Grid para tratar registros não encontrados ou filtro não aplicado -->
      <div :class="['text-center card-info-search', isMobile ? 'm-2' : 'm-5']">
        <div v-if="!this.listaBalancos.length" :class="{ 'd-flex align-items-center justify-content-center mt-2': isMobile }">
          <div>
            <span class="text-center">
              <img :src="iconeSearch" />
            </span>
          </div>
          <div>
            <span :class="['text-uppercase text-muted', isMobile ? 'fz-70' : 'fz-95']"> Informe os filtros desejados para iniciar a consulta </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import loading from '@/common/loading';
import dataUtils from '@/common/date';
import Switches from 'vue-switches';

//URLs Icones/Imagens
const imgIconSearch = require('@/assets/Images/linx-icon-ilustrativo/search.png');
import statusBalanco from '@/domain/status-balanco';

export default {
  name: 'lista-balanco',
  components: {
    Switches,
  },
  data() {
    return {
      telaPronta: false,
      iconeSearch: imgIconSearch,
      statusBalanco,
      listaBalancos: [],
      exibirFinalizados: false,
      filtro: {
        dataInicial: null,
        dataFinal: null,
        empresa: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'appData/ehWMSMobile',
      empresasUsuario: 'auth/empresasUsuario',
      filtrosBalanco: 'balanco/filtrosBalanco',
      balancoSelecionado: 'balanco/balancoSelecionado',
    }),
    habilitarExecucaoBalanco() {
      return this.balancoSelecionado && this.balancoSelecionado.IdStatusBalanco != this.statusBalanco.FINALIZADO.value && this.balancoSelecionado.IdStatusBalanco != this.statusBalanco.CANCELADO.value;
    },
    listaDeBalancos() {
      var self = this;
      if (!self.exibirFinalizados) {
        return this.listaBalancos.filter(function (balanco) {
          return balanco.IdStatusBalanco != 3;
        });
      } else {
        return this.listaBalancos;
      }
    },
  },
  mounted() {
    this.telaPronta = true;
    this.carregarDadosIniciais();
  },

  methods: {
    async carregarDadosIniciais() {
      let empresa = this.empresasUsuario.filter((emp) => emp.Id == this.filtrosBalanco.idEmpresa)[0];
      this.filtro.dataInicial = dataUtils.formatarDataValida(this.filtrosBalanco.dataInicial, 'DD/MM/YYYY');
      this.filtro.dataFinal = dataUtils.formatarDataValida(this.filtrosBalanco.dataFinal, 'DD/MM/YYYY');
      this.filtro.empresa = `${empresa.Id} - ${empresa.Nome}`;
      var params = {
        idEmpresa: this.filtrosBalanco.idEmpresa,
        dataInicial: dataUtils.converterData(this.filtrosBalanco.dataInicial),
        dataFinal: dataUtils.converterData(this.filtrosBalanco.dataFinal),
      };

      loading.exibir();
      this.$store
        .dispatch('balanco/obterBalancos', params)
        .then((resp) => {
          this.listaBalancos = resp.data.Balancos;

          this.listaBalancos = this.listaBalancos.map((balanco) => {
            //Seções são agrupadas por Id, pois podem possuir até 3 etapas
            let qtdeSecoes = this.$_.chain(balanco.Secao)
              .groupBy((x) => x.Id)
              .map((Id) => ({ Id }))
              .value().length;

            return {
              DataLancamento: balanco.DataLancamento,
              DataLancamentoFormat: dataUtils.formatarDataValida(dataUtils.converterData(balanco.DataLancamento), 'DD/MM/YYYY'),
              Descricao: balanco.Descricao,
              IdBalanco: balanco.IdBalanco,
              IdEmpresa: balanco.IdEmpresa,
              IdStatusBalanco: balanco.IdStatusBalanco,
              QtdeSecao: qtdeSecoes,
              Secao: balanco.Secao,
            };
          });
        })
        .catch((err) => {
          this.$helper.globalErrorHandler(err, 'Não foi possível obter os Balanços, tente novamente');
          this.$router.push({ name: 'filtros-balancos' });
        })
        .finally(() => {
          this.telaPronta = true;
          loading.ocultar();
        });
    },
    selecionaBalanco(balanco) {
      if (balanco.IdBalanco != this.balancoSelecionado.IdBalanco) {
        this.$store.dispatch('balanco/atualizaBalancoSelecionado', balanco);
      }
    },
    executarBalanco() {
      this.$router.push({
        name: 'secao-balanco',
        params: { idBalanco: this.balancoSelecionado.IdBalanco },
      });
    },
    obterDescricaoStatusBalanco(idStatus) {
      switch (idStatus) {
        case 1:
          return this.statusBalanco.PLANEJADO.text;
        case 2:
          return this.statusBalanco.EM_ANDAMENTO.text;
        case 3:
          return this.statusBalanco.FINALIZADO.text;
        case 7:
          return this.statusBalanco.CANCELADO.text;
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.border-primary {
  border-width: 2px !important;
}
#idGridBalancos {
  margin-bottom: 2.5rem;
}
::v-deep .vue-switcher.vue-switcher-theme--default.vue-switcher-color--yellow div {
  background-color: #e0c58b !important;
}

::v-deep .vue-switcher.vue-switcher-theme--default.vue-switcher-color--yellow div:after {
  background-color: #ffb200 !important;
}

::v-deep .vue-switcher.vue-switcher--unchecked.vue-switcher-theme--default.vue-switcher-color--yellow div {
  background-color: #d1d1d1 !important;
}

::v-deep .vue-switcher.vue-switcher.vue-switcher--unchecked.vue-switcher-theme--default.vue-switcher-color--yellow div:after {
  background-color: #a3a2a2 !important;
}
</style>
